import { Form, Popconfirm, Switch, Table, Tooltip, notification } from "antd";
import { ColumnsType } from "antd/es/table";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { Icon } from "ts-react-feather-icons";
import { ActionType } from "../../constants/actionType";
import { UserRole } from "../../constants/userRole";
import { deleteEpisode, getEpisodeDetail, getEpisodes, getStoryCharacters, updateEpisode } from "../../helpers/api/story";
import { useUser } from "../../hooks";
import User from "../../model/User";
import CustomPagination from "../CustomPagination";
import EpisodeDrawer from "./EpisodeDrawer";
import EpisodeImageDrawer from "./EpisodeImageDrawer";
import {EpisodeModel} from "../../model/Eposode";
import {Link} from "react-router-dom";

const EpisodeTable = ({
                          story
                      }: any) => {

    const [pageSize, setPageSize] = useState<number>(10);
    const [pageIndex, setPageIndex] = useState<number>(1);
    const [totalRecord, setTotalRecord] = useState<number>(0);
    const [episodes, setEpisodes] = useState<any[]>([]);
    const [episodeDetail, setEpisodeDetail] = useState<any>(null);
    const [actionType, setActionType] = useState<string>("");
    const [episodeDrawerVisibility, setEpisodeDrawerVisibility] = useState<boolean>(false);
    const [episodeImageDrawerVisibility, setEpisodeImageDrawerVisibility] = useState<boolean>(false);

    const currentLoggedInUser = useUser().user as User || null;
    const [api, contextHolder] = notification.useNotification();

    const [form] = Form.useForm();
    const [searchLoading, setSearchLoading] = useState<boolean>(false);

    const [characters, setCharacters] = useState<any[]>([]);

    const showError = (errorMessage: string) => {
        api.error({
            message: `Error notification`,
            description: <span className="text-danger">{errorMessage || 'There\'re some errors occured!'}</span>,
            placement: 'topRight'
        });
    }

    const columns: ColumnsType<EpisodeModel> = [
        {
            title: "No.",
            dataIndex: "noNumber"
        },
        {
            title: "Name",
            dataIndex: "name",
            render: (text, record, index) => {
                return (
                    <span>
                        {record.defaultImageUrl && <img src={record.defaultImageUrl} className="image rounded" style={{ maxWidth: "35px" }} />}
                        {"  "}
                        {record.name}
                    </span>
                )
            },
        },
        {
            title: "Level",
            dataIndex: "level",
        },
        {
            title: "Expected chat number",
            dataIndex: "expectedChatNumber",
        },
        {
            title: "Price (gems)",
            dataIndex: "price",
        },
        {
            title: "Created at",
            dataIndex: "createdAt",
            render: (text, record, index) => {
                return <span>{record?.createdAt ? new Date(record?.createdAt).toLocaleString() : ''}</span>
            }
        },
        {
            title: "Modified by",
            dataIndex: "modifiedBy",
        },
        {
            title: "Published?",
            dataIndex: "activated",
            render: (text, record, index) => {
                return (
                    <Popconfirm
                        title={`${(record.status && record.status === "active") ? "Deactivate" : "Activate"} episode`}
                        description={`Are you sure to ${(record.status && record.status === "active") ? "deactivate" : "activate"} this episode?`}
                        onConfirm={() => changeActivation(record.id, record.status)}
                        okText="OK"
                        cancelText="Cancel"
                    >
                        <Switch checked={record.status === "active"}
                                disabled={
                                    (!currentLoggedInUser?.roles?.includes(UserRole.SUPER_ADMIN)
                                        && !currentLoggedInUser?.roles?.includes(UserRole.ADMIN))
                                }
                        />
                    </Popconfirm>
                )
            },
        },
        {
            title: "Action",
            dataIndex: "action",
            width: 150,
            render: (text, record, index) => {
                return (
                    (currentLoggedInUser?.roles?.includes(UserRole.SUPER_ADMIN) || currentLoggedInUser?.roles?.includes(UserRole.ADMIN)) && (
                        <>
                            <Tooltip title="Image management">
                                <span role="button"
                                      onClick={() => triggerShowImage(record.id)}
                                >
                                    <Icon name="image" color="#fd7e14" size={16} />
                                </span>
                            </Tooltip>
                            <Tooltip title="Update">
                                <span role="button"
                                      onClick={() => triggerUpdate(record.id)}
                                      className="d-inline-block ms-1"
                                >
                                    <Icon name="edit-2" color="#0d6efd" size={16} />
                                </span>
                            </Tooltip>
                            <Tooltip title="Chat">
                                <Link to={`/stories/${story.id}/episodes/${record.id}/chat`} className="d-inline-block ms-1">
                                    <Icon name="message-circle" color="#0d6efd" size={16} />
                                </Link>
                            </Tooltip>
                            <Tooltip title="Delete">
                                <Popconfirm
                                    title={`Delete episode`}
                                    description={`Are you sure to delete this episode?`}
                                    onConfirm={() => triggerDelete(record)}
                                    okText="OK"
                                    cancelText="Cancel"
                                >
                                    <span role="button" className="d-inline-block ms-1">
                                        <Icon name="trash-2" color="#dc3545" size={16} />
                                    </span>
                                </Popconfirm>
                            </Tooltip>
                        </>
                    )
                );
            },
        },
    ];

    useEffect(() => {
        if (!story) {
            return;
        }
        search();
    }, [pageIndex]);

    useEffect(() => {
        if (!story) {
            return;
        }
        if (pageIndex === 1) {
            search();
            return;
        }
        setPageIndex(1);
    }, [pageSize]);

    const search = async () => {
        setSearchLoading(true);
        const searchObj: any = {
            pageIndex: pageIndex - 1,
            pageSize,
        };
        try {
            const response = await getEpisodes(story.id, searchObj);
            if (response.status === 200) {
                const data: any[] = [];
                response.data.data.forEach((item: any, index: number) => {
                    data.push({
                        ...item,
                        noNumber: (pageIndex - 1) * pageSize + index + 1,
                    });
                });
                setEpisodes(data);
                setTotalRecord(response.data.totalRecord);
            }
        } catch (err) {
            console.error(err);
            api.error({
                message: `Error notification`,
                description: <span className="text-danger">{err as string || 'There\'re some errors occured!'}</span>,
                placement: 'topRight'
            });
        }
        setSearchLoading(false);
    }

    const changeActivation = async (episodeId: string, currentStatus: string) => {
        const episodeTemp = await getEpisodeDetail(story.id, episodeId);
        setEpisodeDetail(episodeTemp.data.data);
        const response = await updateEpisode(story.id, episodeId, { ...episodeTemp.data.data, status: currentStatus === "active" ? "inactive" : "active" });
        if (response.status === 200) {
            api.success({
                message: `Successfull notification`,
                description: (currentStatus === "active" ? 'Deactivate' : 'Activate') + ' episode successfully!',
                placement: 'topRight'
            });
            search();
        }
    }

    const triggerUpdate = async (episodeId: string) => {
        try {
            if (!episodeId || episodeId?.trim()?.length === 0) {
                return;
            }
            const episodeTemp = await getEpisodeDetail(story.id, episodeId);
            await getCharacters();
            setEpisodeDetail(episodeTemp.data.data);
            setActionType(ActionType.UPDATE);
            setEpisodeDrawerVisibility(true);
        } catch (err) {
            console.error(err);
            showError(err as string);
        }
    }

    const triggerShowImage = async (episodeId: string) => {
        try {
            if (!episodeId || episodeId?.trim()?.length === 0) {
                return;
            }
            const episodeTemp = await getEpisodeDetail(story.id, episodeId);
            setEpisodeDetail(episodeTemp.data.data);
            setEpisodeImageDrawerVisibility(true);
        } catch (err) {
            console.error(err);
            showError(err as string);
        }
    }



    const triggerDelete = async (episodeModel: EpisodeModel) => {
        try {
            if (!episodeModel || !episodeModel?.id) {
                return;
            }
            if (episodeModel.status === "active") {
                showError("Cannot delete active episode");
                return;
            }
            await deleteEpisode(story.id, episodeModel.id);
        } catch (err) {
            console.error(err);
            showError(err as string);
        }
        search();
    }

    const getCharacters = async () => {
        try {
            const response = await getStoryCharacters(story.id, { pageSize: 100, pageIndex: 0 });
            if (response.status === 200) {
                // const data = response.data.data.filter((item: any) => item?.status === "active");
                setCharacters(response.data.data);
            }
        } catch (err) {
            console.error(err);
            api.error({
                message: `Error notification`,
                description: <span className="text-danger">{err as string || 'There\'re some errors occured!'}</span>,
                placement: 'topRight'
            });
        }
    }

    return (
        <>
            {contextHolder}

            <EpisodeDrawer
                story={story}
                drawerVisibility={episodeDrawerVisibility}
                setdDrawerVisibility={setEpisodeDrawerVisibility}
                episodeDetail={episodeDetail}
                setEpisodeDetail={setEpisodeDetail}
                actionType={actionType}
                setActionType={setActionType}
                characters={characters}
                setCharacters={setCharacters}
                onSuccess={() => {
                    api.success({
                        message: `Successfull notification`,
                        description: (actionType === ActionType.CREATE ? "Create" : "Update") + ' episode successfully!',
                        placement: 'topRight'
                    });
                    setEpisodeDetail(null);
                    setActionType("");
                    search();
                }}
                onFailure={(errorMessage: string) => {
                    showError(errorMessage);
                }}
            />

            <EpisodeImageDrawer
                storyId={story.id}
                drawerVisibility={episodeImageDrawerVisibility}
                setdDrawerVisibility={setEpisodeImageDrawerVisibility}
                episodeDetail={episodeDetail}
                setEpisodeDetail={setEpisodeDetail}
            />


            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col sm={6} className="mb-2">
                                    <span className="fw-bold lead text-success">EPISODES</span>
                                </Col>
                                <Col sm={6} className="mb-2">
                                    <div className="text-sm-end">
                                        <Button variant="success" className="mb-1 py-1 me-1" onClick={() => {
                                            search();
                                        }}>
                                            <i className="mdi mdi-refresh me-1"></i> Refresh
                                        </Button>
                                        <Button variant="primary" className="mb-1 py-1 me-1" onClick={async () => {
                                            await getCharacters();
                                            setActionType(ActionType.CREATE);
                                            setEpisodeDrawerVisibility(true);
                                        }}>
                                            <i className="mdi mdi-plus-circle me-1"></i> Create
                                        </Button>
                                    </div>
                                </Col>
                            </Row>

                            <Table
                                loading={searchLoading}
                                rowKey={"noNumber"}
                                className="table table-hover m-0 table-centered dt-responsive nowrap w-100"
                                dataSource={episodes}
                                columns={columns}
                                pagination={false}
                            />

                            {episodes?.length !== 0 && (
                                <CustomPagination
                                    pageIndex={pageIndex}
                                    pageSize={pageSize}
                                    totalItem={totalRecord}
                                    setPageSize={setPageSize}
                                    setPageIndex={setPageIndex}
                                />
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default EpisodeTable;
