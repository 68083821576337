import {
    Col as AntdCol,
    Row as AntdRow,
    Form,
    Image,
    Input,
    Modal,
    Popconfirm,
    Switch,
    Table,
    Tooltip,
    Upload,
    UploadFile,
    notification,
    Select, DatePicker, DatePickerProps
} from "antd";
import { ColumnsType } from "antd/es/table";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { Icon } from "ts-react-feather-icons";
import { ActionType } from "../../constants/actionType";
import { UserRole } from "../../constants/userRole";
import { deleteCharacter, getCharacterDetail, getStoryCharacters, updateCharacter } from "../../helpers/api/story";
import { useUser } from "../../hooks";
import User from "../../model/User";
import CustomPagination from "../CustomPagination";
import CharacterDrawer from "./CharacterDrawer";
import CharacterImageDrawer from "./CharacterImageDrawer";
import TextArea from "antd/es/input/TextArea";
import { RcFile, UploadProps } from "antd/es/upload";
import {Option} from "antd/es/mentions";
import dayjs from "dayjs";

interface CharacterModel {
    key: React.Key;
    id: string;
    storyId: string;
    name: string;
    description: string;
    status: string;
    defaultImageUrl: string;
    createdBy: string;
    modifiedBy: string;
    createdAt: Date;
    modifiedAt: Date;
    generalInformation: string;
    type: string;
}

const notificationCharacterValidation = {
    title: [
        {required: true, message: 'Title is required'},
        {max: 100, message: 'Title must be less than 100 characters'},
        {
            validator: (_: any, value: any) => {
                if (value && value.trim().length === 0) {
                    return Promise.reject(new Error('Title cannot be only whitespace'));
                }
                return Promise.resolve();
            }
        }
    ],
    content: [
        {required: true, message: 'Content is required'},
        {max: 2000, message: 'Content must be less than 2000 characters'},
        {
            validator: (_: any, value: any) => {
                if (value && value.trim().length === 0) {
                    return Promise.reject(new Error('Content cannot be only whitespace'));
                }
                return Promise.resolve();
            }
        }
    ],
    contentType: [
        {required: true, message: 'Status is required'},
    ],
    targetType: [
        {required: true, message: 'Rarity is required'},
    ],
    sendTimeOption: [
        {required: true, message: 'Time option is required'},
    ]
}

const CharacterTable = ({
                            story
                        }: any) => {

    const [pageSize, setPageSize] = useState<number>(10);
    const [pageIndex, setPageIndex] = useState<number>(1);
    const [totalRecord, setTotalRecord] = useState<number>(0);
    const [characters, setCharacters] = useState<any[]>([]);
    const [characterDetail, setCharacterDetail] = useState<any>(null);
    const [actionType, setActionType] = useState<string>("");
    const [characterDrawerVisibility, setCharacterDrawerVisibility] = useState<boolean>(false);
    const [characterImageDrawerVisibility, setCharacterImageDrawerVisibility] = useState<boolean>(false);
    const [characterActivationModalVisibility, setCharacterActivationModalVisibility] = useState<boolean>(false);

    const currentLoggedInUser = useUser().user as User || null;
    const [api, contextHolder] = notification.useNotification();

    const [form] = Form.useForm();
    const formValueObj = Form.useWatch([], form);
    const [searchLoading, setSearchLoading] = useState<boolean>(false);

    const [confirmPublishState, setConfirmPublishState] = useState<{ [key: string]: boolean }>({});

    const handleConfirmPublishChange = (recordId: string, value: boolean) => {
        setConfirmPublishState((prevState) => ({
            ...prevState,
            [recordId]: value,
        }));
    };
    const [sendNotification, setSendNotification] = useState<boolean>(false);

    const [submittables, setSubmittable] = useState<boolean>(false);

    useEffect(() => {
        form.validateFields({ validateOnly: true })
            .then(
                () => { setSubmittable(true) },
                () => { setSubmittable(false) },
            );
    }, [formValueObj]);

    const showError = (errorMessage: string) => {
        api.error({
            message: `Error notification`,
            description: <span className="text-danger">{errorMessage || 'There\'re some errors occured!'}</span>,
            placement: 'topRight'
        });
    }

    const columns: ColumnsType<CharacterModel> = [
        {
            title: "No.",
            dataIndex: "noNumber"
        },
        {
            title: "Name",
            dataIndex: "name",
            render: (text, record, index) => {
                return (
                    <span>
                        {record.defaultImageUrl && <img src={record.defaultImageUrl} className="image rounded" style={{ maxWidth: "35px" }} />}
                        {"  "}
                        {record.name}
                    </span>
                )
            },
        },
        {
            title: "Type",
            dataIndex: "type",
        },
        {
            title: "Created at",
            dataIndex: "createdAt",
            render: (text, record, index) => {
                return <span>{record?.createdAt ? new Date(record?.createdAt).toLocaleString() : ''}</span>
            }
        },
        {
            title: "Modified by",
            dataIndex: "modifiedBy",
        },
        {
            title: "Published?",
            dataIndex: "activated",
            render: (text, record, index) => {
                const confirmPublish = confirmPublishState[record.id] || false;
                return (
                    <Popconfirm
                        title={`${(record.status && record.status === "active") ? "Deactivate" : "Activate"} character`}
                        description={
                            <>

                                {(record.status && record.status !== "active") && (
                                    <div>
                                        <Switch
                                            checked={sendNotification}
                                            onChange={(checked) => {
                                                setSendNotification(checked);
                                                if (checked) {
                                                    setCharacterDetail(record);
                                                    form.setFieldValue("title", `Character's activated`)
                                                    form.setFieldValue("content", `Character '${record.name}' has been activated. Click to see!`)
                                                    setCharacterActivationModalVisibility(true);
                                                }
                                            }
                                            }
                                        /> Send notification
                                    </div>
                                )}
                                <div>Are you sure
                                    to {(record.status && record.status === "active") ? "deactivate this character" : "activate this character"}?
                                </div>
                            </>
                        }
                        open={confirmPublish}
                        onConfirm={() => {
                            changeActivation(record.storyId, record.id, record.status, sendNotification);
                            setSendNotification(false);
                            setCharacterDetail(record);
                            handleConfirmPublishChange(record.id, false)
                        }}
                        onCancel={() => handleConfirmPublishChange(record.id, false)}
                        okText="OK"
                        cancelText="Cancel"
                    >
                        <Switch checked={record.status === "active"}
                                disabled={
                                    (!currentLoggedInUser?.roles?.includes(UserRole.SUPER_ADMIN)
                                        && !currentLoggedInUser?.roles?.includes(UserRole.ADMIN))
                                }
                                onChange={() => {
                                    handleConfirmPublishChange(record.id, true);
                                }}
                        />
                    </Popconfirm>
                )
            },
        },
        {
            title: "Action",
            dataIndex: "action",
            render: (text, record, index) => {
                return (
                    (currentLoggedInUser?.roles?.includes(UserRole.SUPER_ADMIN) || currentLoggedInUser?.roles?.includes(UserRole.ADMIN)) && (
                        <>
                            <Tooltip title="Image management">
                                <span role="button"
                                      onClick={() => triggerShowImage(record.id)}
                                >
                                    <Icon name="image" color="#fd7e14" size={16} />
                                </span>
                            </Tooltip>
                            <Tooltip title="Update">
                                <span role="button"
                                      onClick={() => triggerUpdate(record.storyId, record.id)}
                                      className="d-inline-block ms-1"
                                >
                                    <Icon name="edit-2" color="#0d6efd" size={16} />
                                </span>
                            </Tooltip>
                            <Tooltip title="Delete">
                                <Popconfirm
                                    title={`Delete character`}
                                    description={`Are you sure to delete this character?`}
                                    onConfirm={() => triggerDeleteCharacter(record)}
                                    okText="OK"
                                    cancelText="Cancel"
                                >
                                    <span role="button" className="d-inline-block ms-1">
                                        <Icon name="trash-2" color="#dc3545" size={16} />
                                    </span>
                                </Popconfirm>
                            </Tooltip>
                        </>
                    )
                );
            },
        },
    ];

    useEffect(() => {
        if (!story) {
            return;
        }
        search();
    }, [pageIndex]);

    useEffect(() => {
        if (!story) {
            return;
        }
        if (pageIndex === 1) {
            search();
            return;
        }
        setPageIndex(1);
    }, [pageSize]);

    const search = async () => {
        setSearchLoading(true);
        const searchObj: any = {
            pageIndex: pageIndex - 1,
            pageSize,
        };
        try {
            const response = await getStoryCharacters(story.id, searchObj);
            if (response.status === 200) {
                const data: any[] = [];
                response.data.data.forEach((item: any, index: number) => {
                    data.push({
                        ...item,
                        noNumber: (pageIndex - 1) * pageSize + index + 1,
                    });
                });
                setCharacters(data);
                setTotalRecord(response.data.totalRecord);
            }
        } catch (err) {
            console.error(err);
            api.error({
                message: `Error notification`,
                description: <span className="text-danger">{err as string || 'There\'re some errors occured!'}</span>,
                placement: 'topRight'
            });
        }
        setSearchLoading(false);
    }

    const changeActivation = async (storyId: string, characterId: string, currentStatus: string, sendNotification: boolean) => {
        const character = await getCharacterDetail(storyId, characterId);
        if (currentStatus === "inactive" && character.data.data.type === "user_character") {
            const char = character.data.data;
            const count = characters.filter(c => c.status === "active" && c.id !== char.id && c.type === "user_character").length;
            if (count >= 1) {
                showError("There must be only 1 active user character at a time");
                return;
            } else {
                if (char.name !== "{{user.name}}") {
                    showError("User character must be '{{user.name}}', please update the name following this rule and activate character!");
                    return;
                }
            }
        }
        let data : any;
        if (sendNotification) {
            data = {
                ...character.data.data,
                status: currentStatus === "active" ? "inactive" : "active",
                notification: {
                    title: formValueObj.title?.trim(),
                    content: formValueObj.content?.trim(),
                    sendTimeOption: formValueObj.sendTimeOption,
                    timeToSend: formValueObj.timeToSend
                }
            }
        } else {
            data = {
                ...character.data.data,
                status: currentStatus === "active" ? "inactive" : "active",
            }
        }
        const response = await updateCharacter(storyId, characterId, data);
        if (response.status === 200) {
            api.success({
                message: `Successfull notification`,
                description: (currentStatus === "active" ? 'Deactivate' : 'Activate') + ' character successfully!',
                placement: 'topRight'
            });
            search();
            setCharacterDetail(null);
            form.resetFields();
            setCharacterActivationModalVisibility(false);
        }
    }

    const triggerUpdate = async (storyId: string, characterId: string) => {
        try {
            if (!characterId || characterId?.trim()?.length === 0) {
                return;
            }
            const char = await getCharacterDetail(storyId, characterId);
            setCharacterDetail(char.data.data);
            setActionType(ActionType.UPDATE);
            setCharacterDrawerVisibility(true);
        } catch (err) {
            console.error(err);
            showError(err as string);
        }
    }

    const triggerDeleteCharacter = async (char: CharacterModel) => {
        try {
            if (!char || !char?.id) {
                return;
            }
            if (char.status === "active") {
                showError("Cannot delete active character");
                return;
            }
            await deleteCharacter(story.id, char.id);
        } catch (err) {
            console.error(err);
            showError(err as string);
        }
        search();
    }

    const triggerShowImage = async (characterId: string) => {
        try {
            if (!characterId || characterId?.trim()?.length === 0) {
                return;
            }
            const characterTmp = await getCharacterDetail(story.id, characterId);
            setCharacterDetail(characterTmp.data.data);
            setCharacterImageDrawerVisibility(true);
        } catch (err) {
            console.error(err);
            showError(err as string);
        }
    }

    return (
        <>
            {contextHolder}

            <CharacterDrawer
                story={story}
                drawerVisibility={characterDrawerVisibility}
                setdDrawerVisibility={setCharacterDrawerVisibility}
                characterDetail={characterDetail}
                setCharacterDetail={setCharacterDetail}
                actionType={actionType}
                setActionType={setActionType}
                characters={characters}
                onSuccess={() => {
                    api.success({
                        message: `Successfull notification`,
                        description: (actionType === ActionType.CREATE ? "Create" : "Update") + ' character successfully!',
                        placement: 'topRight'
                    });
                    setCharacterDetail(null);
                    setActionType("");
                    search();
                }}
                onFailure={(errorMessage: string) => {
                    showError(errorMessage);
                }}
            />

            <CharacterImageDrawer
                storyId={story.id}
                drawerVisibility={characterImageDrawerVisibility}
                setdDrawerVisibility={setCharacterImageDrawerVisibility}
                characterDetail={characterDetail}
                setCharacterDetail={setCharacterDetail}
            />

            <Modal
                title="Character activation notification info"
                open={characterActivationModalVisibility}
                onOk={() => setCharacterActivationModalVisibility(false)}
                okButtonProps={{ disabled: !submittables }}
                onCancel={() => {
                    form.resetFields();
                    setCharacterDetail(null);
                    setCharacterActivationModalVisibility(false);
                    setSendNotification(false);
                }}
                zIndex={9999}
            >
                <Form layout="vertical" autoComplete='off' form={form}>
                    <AntdRow gutter={16} className='d-flex justify-content-center'>
                        <Form.Item
                            name="image"
                            label="Image"
                        >
                            {characterDetail?.defaultImageUrl
                                ? (<Image
                                    width={200}
                                    src={characterDetail?.defaultImageUrl}
                                />)
                                : (<span className="text-danger">No default image available</span>)
                            }
                        </Form.Item>
                    </AntdRow>
                    <AntdRow gutter={16}>
                        <AntdCol span={24}>
                            <Form.Item
                                name="title"
                                label="Title"
                                rules={notificationCharacterValidation.title}
                                required={true}
                            >
                                <Input placeholder="Please enter title" />
                            </Form.Item>
                        </AntdCol>
                    </AntdRow>
                    <AntdRow gutter={16}>
                        <AntdCol span={24}>
                            <Form.Item
                                name="content"
                                label="Content"
                                rules={notificationCharacterValidation.content}
                                required={true}
                            >
                                <TextArea rows={4} placeholder="Enter content" maxLength={5000} />
                            </Form.Item>
                        </AntdCol>
                    </AntdRow>
                    <AntdRow gutter={16}>
                        <AntdCol span={12}>
                            <Form.Item
                                name="sendTimeOption"
                                label="Send time option"
                                rules={notificationCharacterValidation.sendTimeOption}
                            >
                                <Select
                                    placeholder="Please select a type"
                                    value={formValueObj?.sendTimeOption}
                                    onChange={(e) => {
                                        form.setFieldsValue({sendTimeOption: e});
                                        if (e === "now") {
                                            form.setFieldsValue({timeToSend: null});
                                        }
                                    }
                                    }
                                >
                                    <Option value="now">Now</Option>
                                    <Option value="custom">Set a future time</Option>
                                </Select>
                            </Form.Item>
                        </AntdCol>
                        <AntdCol span={12}>
                            <Form.Item
                                name="timeToSend"
                                label="Time to send"
                                rules={[
                                    ({getFieldValue}) => ({
                                        validator(_, value) {
                                            if (getFieldValue('sendTimeOption') === "now") {
                                                return Promise.resolve();
                                            }
                                            if (!value) {
                                                return Promise.reject(new Error('Time to send is required'));
                                            }
                                            if (value.isBefore(new Date()) || value.isSame(new Date())) {
                                                return Promise.reject(new Error('Time to send must be future time'));
                                            }
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <DatePicker
                                    disabled={formValueObj?.sendTimeOption === "now" || !formValueObj?.sendTimeOption}
                                    className='w-100'
                                    showTime
                                    format={'DD/MM/YYYY HH:mm'}
                                    disabledDate={(current) => {
                                        // Can not select days before today and today
                                        return current && current < dayjs().startOf('day');
                                    }}
                                    onOk={(value: DatePickerProps['value']) => {
                                        value = value?.set("second", 0);
                                        form.setFieldsValue({timeToSend: value});
                                    }}
                                />
                            </Form.Item>
                        </AntdCol>
                    </AntdRow>
                </Form>
            </Modal>

            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col sm={6} className="mb-2">
                                    <span className="fw-bold lead text-success">CHARACTERS</span>
                                </Col>
                                <Col sm={6} className="mb-2">
                                    <div className="text-sm-end">
                                        <Button variant="success" className="mb-1 py-1 me-1" onClick={() => {
                                            search();
                                        }}>
                                            <i className="mdi mdi-refresh me-1"></i> Refresh
                                        </Button>
                                        <Button variant="primary" className="mb-1 py-1 me-1" onClick={() => {
                                            setActionType(ActionType.CREATE);
                                            setCharacterDrawerVisibility(true);
                                        }}>
                                            <i className="mdi mdi-plus-circle me-1"></i> Create
                                        </Button>
                                    </div>
                                </Col>
                            </Row>

                            <Table
                                loading={searchLoading}
                                rowKey={"noNumber"}
                                className="table table-hover m-0 table-centered dt-responsive nowrap w-100"
                                dataSource={characters}
                                columns={columns}
                                pagination={false}
                            />

                            {characters?.length !== 0 && (
                                <CustomPagination
                                    pageIndex={pageIndex}
                                    pageSize={pageSize}
                                    totalItem={totalRecord}
                                    setPageSize={setPageSize}
                                    setPageIndex={setPageIndex}
                                />
                            )}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    )
}

export default CharacterTable;
