import {
  Button as AntdButton,
  Col as AntdCol,
  Row as AntdRow,
  Form,
  Image,
  Input,
  Modal,
  Popconfirm,
  Select,
  Switch,
  Table,
  Tooltip,
  notification,
  DatePicker, DatePickerProps, Upload, UploadFile
} from "antd";
import {Option} from "antd/es/mentions";
import {ColumnsType} from "antd/es/table";
import React, {useEffect, useState} from "react";
import {Button, Card, Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Icon} from "ts-react-feather-icons";
import CustomPagination from "../../components/CustomPagination";
import PageTitle from "../../components/PageTitle";
import StoryDrawer from "../../components/Story/StoryDrawer";
import {ActionType} from "../../constants/actionType";
import {UserRole} from "../../constants/userRole";
import {
  deleteStory,
  getEpisodeImages,
  getEpisodes,
  getStoryDetail,
  searchStory,
  updateStoryActivation
} from "../../helpers/api/story";
import {useUser} from "../../hooks";
import User from "../../model/User";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";
import {RcFile, UploadProps} from "antd/es/upload";

interface StoryModel {
  key: React.Key;
  id: string;
  name: string;
  description: string;
  developmentDirection: string;
  defaultImageUrl: string;
  status: string;
  stage: string;
  createdBy: string;
  modifiedBy: string;
  createdAt: Date;
  modifiedAt: Date;
}

const notificationDrawerValidation = {
  title: [
    {required: true, message: 'Title is required'},
    {max: 100, message: 'Title must be less than 100 characters'},
    {
      validator: (_: any, value: any) => {
        if (value && value.trim().length === 0) {
          return Promise.reject(new Error('Title cannot be only whitespace'));
        }
        return Promise.resolve();
      }
    }
  ],
  content: [
    {required: true, message: 'Content is required'},
    {max: 2000, message: 'Content must be less than 2000 characters'},
    {
      validator: (_: any, value: any) => {
        if (value && value.trim().length === 0) {
          return Promise.reject(new Error('Content cannot be only whitespace'));
        }
        return Promise.resolve();
      }
    }
  ],
  contentType: [
    {required: true, message: 'Status is required'},
  ],
  targetType: [
    {required: true, message: 'Rarity is required'},
  ],
  sendTimeOption: [
    {required: true, message: 'Time option is required'},
  ],
  sourceEventType: [
    {required: true, message: 'Notification type is required'},
  ]
}

const Story = () => {

  const [pageSize, setPageSize] = useState<number>(10);
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [totalRecord, setTotalRecord] = useState<number>(0);
  const [stories, setStories] = useState<any[]>([]);
  const [storyDetails, setStoryDetails] = useState<any>(null);
  const [actionType, setActionType] = useState<string>("");
  const [storyDrawerVisibility, setStoryDrawerVisibility] = useState<boolean>(false);
  const [storyActivationModalVisibility, setStoryActivationModalVisibility] = useState<boolean>(false);

  const currentLoggedInUser = useUser().user as User || null;
  const [api, contextHolder] = notification.useNotification();

  const [form] = Form.useForm();
  const formValueObj = Form.useWatch([], form);
  const [searchLoading, setSearchLoading] = useState<boolean>(false);

  const [confirmPublishState, setConfirmPublishState] = useState<{ [key: string]: boolean }>({});

  const handleConfirmPublishChange = (recordId: string, value: boolean) => {
    setConfirmPublishState((prevState) => ({
      ...prevState,
      [recordId]: value,
    }));
  };
  const [sendNotification, setSendNotification] = useState<boolean>(false);

  const [submittables, setSubmittable] = useState<boolean>(false);

  // for image
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [images, setImages] = useState<UploadFile[]>([]);
  const getBase64 = (file: RcFile): Promise<string> =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
      });

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
  };

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) =>
      setImages(newFileList);

  const uploadButton = (
      <div>
        {/* <PlusOutlined rev={1} /> */}
        <Icon name="plus" size={16} />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
  );

  useEffect(() => {
    form.validateFields({ validateOnly: true })
        .then(
            () => { setSubmittable(true) },
            () => { setSubmittable(false) },
        );
  }, [formValueObj]);

  const showError = (errorMessage: string) => {
    api.error({
      message: `Error notification`,
      description: <span className="text-danger">{errorMessage || 'There\'re some errors occured!'}</span>,
      placement: 'topRight'
    });
  }

  const columns: ColumnsType<StoryModel> = [
    {
      title: "No.",
      dataIndex: "noNumber"
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (text, record, index) => {
        return (
            <Link to={`/stories/${record.id}`} className="text-primary">
              {record.defaultImageUrl &&
                  <img src={record.defaultImageUrl} className="image rounded" style={{maxWidth: "35px"}}/>}
              {" "}
              {record.name}
            </Link>
        )
      },
    },
    {
      title: "Created by",
      dataIndex: "createdBy",
    },
    {
      title: "Created at",
      dataIndex: "createdAt",
      render: (text, record, index) => {
        return <span>{record?.createdAt ? new Date(record?.createdAt).toLocaleString() : ''}</span>
      }
    },
    {
      title: "Modified by",
      dataIndex: "modifiedBy",
    },
    {
      title: "Published?",
      dataIndex: "activated",
      render: (text, record, index) => {
        const confirmPublish = confirmPublishState[record.id] || false;
        return (
            <Popconfirm
                title={`${(record.status && record.status === "active") ? "Deactivate" : "Activate"} story`}
                description={
                  <>

                    {(record.status && record.status !== "active") && (
                        <div>
                          <Switch
                              checked={sendNotification}
                              onChange={async (checked) => {
                                setSendNotification(checked);
                                if (checked) {
                                  const data = await getEpisodes(record.id, null);
                                  if (data.status === 200) {
                                    const episodes = data.data.data;
                                    const  episode1 = episodes.find((item: any) => item.level === 1);
                                    const imageEpisodes = await getEpisodeImages(record.id, episode1?.id, null);
                                    if (imageEpisodes.status === 200) {
                                      const images = imageEpisodes.data.data;
                                      if (images.length > 0) {
                                        const image = images.find((item: any) => item.type === 'episode_image');
                                        if (image) {
                                          const urlTemp = image.url.split("/");
                                          setImages([{
                                            uid: "1",
                                            name: urlTemp[urlTemp.length - 1],
                                            status: 'done',
                                            url: image.url,
                                          }]);
                                        }
                                      }
                                    }
                                  }
                                  setStoryDetails(record);
                                  form.setFieldValue("title", `Story's activated`)
                                  form.setFieldValue("content", `Story '${record.name}' has been activated. Click to see!`)
                                  setStoryActivationModalVisibility(true);
                                }
                              }
                              }
                          /> Send notification
                        </div>
                    )}
                    <div>Are you sure
                      to {(record.status && record.status === "active") ? "deactivate this story" : "activate this story"}?
                    </div>
                  </>
                }
                open={confirmPublish}
                onConfirm={() => {
                  changeActivation(record.id, record.status, sendNotification);
                  setSendNotification(false);
                  setStoryDetails(record);
                  handleConfirmPublishChange(record.id, false)
                }}
                onCancel={() => handleConfirmPublishChange(record.id, false)}
                okText="OK"
                cancelText="Cancel"
            >
              <Switch checked={record.status === "active"}
                      disabled={
                        (!currentLoggedInUser?.roles?.includes(UserRole.SUPER_ADMIN)
                            && !currentLoggedInUser?.roles?.includes(UserRole.ADMIN))
                      }
                      onChange={() => {
                        handleConfirmPublishChange(record.id, true);
                      }
                      }
              />
            </Popconfirm>
        )
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 80,
      render: (text, record, index) => {
        return (
            (currentLoggedInUser?.roles?.includes(UserRole.SUPER_ADMIN) || currentLoggedInUser?.roles?.includes(UserRole.ADMIN)) && (
                <>
                  <Tooltip title="Update">
                <span role="button"
                      onClick={() => triggerUpdate(record.id)}
                >
                  <Icon name="edit-2" color="#0d6efd" size={16}/>
                </span>
                  </Tooltip>
                  {" "}
                  <Tooltip title="Delete">
                    <Popconfirm
                        title={`Delete story`}
                        description={`Are you sure to delete this story?`}
                        onConfirm={() => triggerDeleteStory(record)}
                        okText="OK"
                        cancelText="Cancel"
                    >
                  <span role="button">
                    <Icon name="trash-2" color="#dc3545" size={16}/>
                  </span>
                    </Popconfirm>
                  </Tooltip>
                </>
            )
        );
      },
    },
  ];

  useEffect(() => {
    search();
  }, [pageIndex]);

  useEffect(() => {
    if (pageIndex === 1) {
      search();
      return;
    }
    setPageIndex(1);
  }, [pageSize]);

  const search = async () => {
    setSearchLoading(true);
    const searchObj: any = {
      pageIndex: pageIndex - 1,
      pageSize,
    };
    if (formValueObj?.name) {
      searchObj["name"] = formValueObj?.name.trim();
    }
    if (formValueObj?.status) {
      searchObj["status"] = formValueObj?.status;
    }
    if (formValueObj?.developmentDirection) {
      searchObj["developmentDirection"] = formValueObj?.developmentDirection?.trim();
    }
    try {
      const response = await searchStory(searchObj);
      if (response.status === 200) {
        const data: any[] = [];
        response.data.data.forEach((item: any, index: number) => {
          data.push({
            ...item,
            noNumber: (pageIndex - 1) * pageSize + index + 1,
          });
        });
        setStories(data);
        setTotalRecord(response.data.totalRecord);
      }
    } catch (err) {
      console.error(err);
      api.error({
        message: `Error notification`,
        description: <span className="text-danger">{err as string || 'There\'re some errors occured!'}</span>,
        placement: 'topRight'
      });
    }
    setSearchLoading(false);
  }

  const changeActivation = async (storyId: string, currentStatus: string, sendNotification: boolean) => {
    const story = await getStoryDetail(storyId);
    setStoryDetails(story.data.data);
    try {
      let data : any;
      if (sendNotification) {
        data = {
          status: currentStatus === "active" ? "inactive" : "active",
          notification: {
            defaultImageUrl: images.length > 0 ? images?.[0]?.url : null,
            title: formValueObj.title?.trim(),
            content: formValueObj.content?.trim(),
            sendTimeOption: formValueObj.sendTimeOption,
            timeToSend: formValueObj.timeToSend,
          }
        }
      }
      else {
        data = {
          status: currentStatus === "active" ? "inactive" : "active"
        }
      }
      const response = await updateStoryActivation(storyId, {
        data: JSON.stringify(data),
        image: images.length > 0 ? images?.[0]?.originFileObj : null
      });
      if (response.status === 200) {
        api.success({
          message: `Successfull notification`,
          description: (currentStatus === "active" ? 'Deactivate' : 'Activate') + ' story successfully!',
          placement: 'topRight'
        });
        search();
        setStoryDetails(null);
        form.resetFields();
        setStoryActivationModalVisibility(false);
      }
    } catch (err) {
      showError(err as string);
    }
  }

  const triggerUpdate = async (storyId: string) => {
    try {
      if (!storyId || storyId?.trim()?.length === 0) {
        return;
      }
      const story = await getStoryDetail(storyId);
      setStoryDetails(story.data.data);
      setActionType(ActionType.UPDATE);
      setStoryDrawerVisibility(true);
    } catch (err) {
      console.error(err);
      showError(err as string);
    }
  }

  const triggerDeleteStory = async (storyModel: StoryModel) => {
    try {
      if (!storyModel || !storyModel?.id) {
        return;
      }
      if (storyModel.status === "active") {
        showError("Cannot delete an active story");
        return;
      }
      await deleteStory(storyModel.id);
    } catch (err) {
      console.error(err);
      showError(err as string);
    }
    search();
  }

  return (
      <>
        {contextHolder}

        <StoryDrawer
            drawerVisibility={storyDrawerVisibility}
            setdDrawerVisibility={setStoryDrawerVisibility}
            storyDetail={storyDetails}
            setStoryDetail={setStoryDetails}
            actionType={actionType}
            setActionType={setActionType}
            onSuccess={() => {
              api.success({
                message: `Successfull notification`,
                description: (actionType === ActionType.CREATE ? "Create" : "Update") + ' story successfully!',
                placement: 'topRight'
              });
              setStoryDetails(null);
              setActionType("");
              search();
            }}
            onFailure={(errorMessage: string) => {
              showError(errorMessage);
            }}
        />

        <Modal
            title="Story activation notification info"
            open={storyActivationModalVisibility}
            onOk={() => setStoryActivationModalVisibility(false)}
            okButtonProps={{disabled: !submittables}}
            onCancel={() => {
              form.resetFields();
              setStoryDetails(null);
              setStoryActivationModalVisibility(false);
              setSendNotification(false);
            }}
            zIndex={9998}
        >
          <Form layout="vertical" autoComplete='off' form={form}>
            <AntdRow gutter={16} className='d-flex justify-content-center'>
              <Form.Item
                  name="image"
                  label="Image"
              >
                <Upload
                    listType="picture-card"
                    fileList={images}
                    onPreview={handlePreview}
                    onChange={handleChange}
                    multiple={false}
                    accept="image/png, image/jpeg, image/jpg"
                >
                  {images.length >= 1 ? null : uploadButton}
                </Upload>
                <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel} zIndex={9999}>
                  <img alt="example" style={{ width: '100%' }} src={previewImage} />
                </Modal>
              </Form.Item>
            </AntdRow>
            <AntdRow gutter={16}>
              <AntdCol span={24}>
                <Form.Item
                    name="title"
                    label="Title"
                    required={true}
                    rules={notificationDrawerValidation.title}
                >
                  <Input placeholder="Please enter title"/>
                </Form.Item>
              </AntdCol>
            </AntdRow>
            <AntdRow gutter={16}>
              <AntdCol span={24}>
                <Form.Item
                    name="content"
                    label="Content"
                    required={true}
                    rules={notificationDrawerValidation.content}
                >
                  <TextArea rows={4} placeholder="Enter content" maxLength={5000}/>
                </Form.Item>
              </AntdCol>
            </AntdRow>
            <AntdRow gutter={16}>
              <AntdCol span={12}>
                <Form.Item
                    name="sendTimeOption"
                    label="Send time option"
                    rules={notificationDrawerValidation.sendTimeOption}
                >
                  <Select
                      placeholder="Please select a type"
                      value={formValueObj?.sendTimeOption}
                      onChange={(e) => {
                        form.setFieldsValue({sendTimeOption: e});
                        if (e === "now") {
                          form.setFieldsValue({timeToSend: null});
                        }
                      }
                      }
                  >
                    <Option value="now">Now</Option>
                    <Option value="custom">Set a future time</Option>
                  </Select>
                </Form.Item>
              </AntdCol>
              <AntdCol span={12}>
                <Form.Item
                    name="timeToSend"
                    label="Time to send"
                    rules={[
                      ({getFieldValue}) => ({
                        validator(_, value) {
                          if (getFieldValue('sendTimeOption') === "now") {
                            return Promise.resolve();
                          }
                          if (!value) {
                            return Promise.reject(new Error('Time to send is required'));
                          }
                          if (value.isBefore(new Date()) || value.isSame(new Date())) {
                            return Promise.reject(new Error('Time to send must be future time'));
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                >
                  <DatePicker
                      disabled={formValueObj?.sendTimeOption === "now" || !formValueObj?.sendTimeOption}
                      className='w-100'
                      showTime
                      format={'DD/MM/YYYY HH:mm'}
                      disabledDate={(current) => {
                        // Can not select days before today and today
                        return current && current < dayjs().startOf('day');
                      }}
                      onOk={(value: DatePickerProps['value']) => {
                        value = value?.set("second", 0);
                        form.setFieldsValue({timeToSend: value});
                      }}
                  />
                </Form.Item>
              </AntdCol>
            </AntdRow>
          </Form>
        </Modal>

        <PageTitle
            breadCrumbItems={[
              {label: "Stories", path: "/apps/stories", active: true},
            ]}
            title={"Stories"}
        />

        <Row>
          <Col>
            <Card>
              <Card.Body>
                <Form layout="vertical" autoComplete='off' form={form}>
                  <AntdRow gutter={16}>
                    <AntdCol span={12}>
                      <Form.Item
                          name="name"
                          label="Name"
                      >
                        <Input placeholder="Please enter collection's name"/>
                      </Form.Item>
                    </AntdCol>
                    <AntdCol span={12}>
                      <Form.Item
                          name="status"
                          label="Status"
                      >
                        <Select placeholder="Please choose the status" allowClear>
                          <Option value="active">Active</Option>
                          <Option value="inactive">Inactive</Option>
                        </Select>
                      </Form.Item>
                    </AntdCol>
                  </AntdRow>
                  <AntdRow gutter={16}>
                    <AntdCol span={12}>
                      <Form.Item
                          name="developmentDirection"
                          label="Development direction"
                      >
                        <Input placeholder="Please enter development direction"/>
                      </Form.Item>
                    </AntdCol>
                  </AntdRow>
                  <AntdRow className="justify-content-center">
                    <AntdCol>
                      <AntdButton type="primary" className="me-1" htmlType="submit"
                                  loading={searchLoading}
                                  onClick={() => {
                                    if (pageIndex === 1) {
                                      search();
                                      return;
                                    }
                                    setPageIndex(1);
                                  }}
                      >
                        Search
                      </AntdButton>
                      <AntdButton type="default" loading={searchLoading} onClick={() => {
                        form.resetFields();
                        if (pageIndex === 1) {
                          search();
                          return;
                        }
                        setPageIndex(1);
                      }}>
                        Reset
                      </AntdButton>
                    </AntdCol>
                  </AntdRow>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col>
            <Card>
              <Card.Body>
                <Row>
                  <Col sm={12} className="mb-2">
                    <div className="text-sm-end">
                      <Button variant="primary" className="mb-1 py-1 me-1" onClick={() => {
                        setActionType(ActionType.CREATE);
                        setStoryDrawerVisibility(true);
                      }}>
                        <i className="mdi mdi-plus-circle me-1"></i> Create
                      </Button>
                    </div>
                  </Col>
                </Row>

                <Table
                    loading={searchLoading}
                    rowKey={"noNumber"}
                    className="table table-hover m-0 table-centered dt-responsive nowrap w-100"
                    dataSource={stories}
                    columns={columns}
                    pagination={false}
                />

                {stories?.length !== 0 && (
                    <CustomPagination
                        pageIndex={pageIndex}
                        pageSize={pageSize}
                        totalItem={totalRecord}
                        setPageSize={setPageSize}
                        setPageIndex={setPageIndex}
                    />
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </>
  );
};

export default Story;
